







































import { Component, Vue } from "vue-property-decorator";
import ConfiguratorModule from "@/store/modules/configurator";

@Component
export default class ConfiguratorMainPage extends Vue {
    private created() {
        ConfiguratorModule.loadLastConfigurationState();
    }

    private get configurationExists() {
        return ConfiguratorModule.selectedCategoryId !== -1;
    }

    private startNewConfiguration() {
        ConfiguratorModule.startConfiguration()
            .then(() => {
                this.$router.push({
                    name: "configuratorConfiguration",
                });
            })
            .catch((err) => console.error(err));
    }
}
